import { Dispatch, SetStateAction, useState } from "react";
import { Input } from "./Input";

export const ColorInput = ({
  state,
  name,
}: {
  state: [string, Dispatch<SetStateAction<string>>];
  name: string;
}) => {
  const [internalText, setInternalText] = useState(state[0]);

  return (
    <Input
      name={name}
      state={[
        internalText,
        (e) => {
          const value = typeof e === "function" ? e(internalText) : e;

          if (value.length > 7) return;
          if (!/^[#]*[a-fA-F0-9]*$/.test(value)) return;

          setInternalText(value);

          if (/^[#]*[a-fA-F0-9]{6}$/.test(value)) {
            state[1](value.startsWith("#") ? value : `#${value}`);
          }
        },
      ]}
      left={
        <div
          style={{
            width: "2rem",
            height: "2rem",
            background: state[0],
            borderRadius: "0.5rem",
            border: "var(--color-darken) 2px solid",
          }}
        />
      }
    />
  );
};
