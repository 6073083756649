import { useRef, useState } from "react";
import { saveAs } from "file-saver";
import { Button } from "./components/Button";
import { Card } from "./components/Card";
import { ColorInput } from "./components/ColorInput";
import { Input } from "./components/Input";
import domtoimage from "dom-to-image";
import "./css/App.css";
import { Town } from "./components/icons/Town";
import { Combo } from "./components/Combo";
import { Mafia } from "./components/icons/Mafia";
import { Neutral } from "./components/icons/Neutral";
import { Other } from "./components/icons/Other";
import _ from "lodash";

const App = () => {
  const [title, setTitle] = useState(`ROLE\nNAME`);

  const [tagline, setTagline] = useState(`Add your tagline here...`);

  const [alignment, setAlignment] = useState(`ALIGNMENT NAME`);

  const [description, setDescription] = useState(
    `
This is the description of the role that you want to explain. Hopefully you can fill in a good amount of explanation as it would probably benefit the look of this thing as well. 

Use two exclamation marks to !bold!

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  `.trim()
  );

  const [win, setWin] = useState(`Add your win condition here...`);

  const [textColor, setTextColor] = useState("#000000");
  const [color1, setColor1] = useState("#FFFFFF");
  const [color2, setColor2] = useState("#37afff");
  const [titleColor1, setTitleColor1] = useState("#212122");
  const [titleColor2, setTitleColor2] = useState("#37afff");
  const [alignmentIcon, setAlignmentIcon] = useState(() => Town);

  const [isLoading, setIsLoading] = useState(false);

  const cardRef = useRef<null | HTMLDivElement>(null);

  return (
    <div id="content-flex">
      <main>
        <Input name="Title" state={[title, setTitle]} rows={2} />

        <Input name="Tagline" state={[tagline, setTagline]} rows={2} />

        <Input
          name="Alignment"
          state={[alignment, (value) => setAlignment(value)]}
        />

        <Combo
          name="Alignment Icon"
          options={[
            { key: "mafia", value: Mafia },
            { key: "neutral", value: Neutral },
            { key: "other", value: Other },
            { key: "town", value: Town },
          ]}
          render={(option, key) => (
            <>
              {option()}
              {_.capitalize(key)}
            </>
          )}
          state={[alignmentIcon, setAlignmentIcon]}
        />

        <Input
          name="Description"
          state={[description, setDescription]}
          rows={7}
        />

        <Input name="Win Condition" state={[win, setWin]} rows={2} />

        <ColorInput name="Text Color" state={[textColor, setTextColor]} />

        <div id="color-grid">
          <ColorInput name="Color 1" state={[color1, setColor1]} />

          <ColorInput name="Color 2" state={[color2, setColor2]} />

          <ColorInput
            name="Title Color 1"
            state={[titleColor1, setTitleColor1]}
          />

          <ColorInput
            name="Title Color 2"
            state={[titleColor2, setTitleColor2]}
          />
        </div>
      </main>

      <div id="card-holder">
        <div ref={cardRef}>
          <Card
            {...{
              title,
              tagline,
              description,
              win,
              textColor,
              color1,
              color2,
              titleColor1,
              titleColor2,
              alignment,
              alignmentIcon,
            }}
          />
        </div>

        <div style={{ height: "1rem" }} />

        <Button
          onClick={async () => {
            if (isLoading) return;
            if (!cardRef.current) return;

            setIsLoading(true);

            try {
              const blob = await domtoimage.toBlob(cardRef.current);

              saveAs(
                blob,
                `card-${title
                  .toLowerCase()
                  .replaceAll("\n", "-")
                  .replaceAll(" ", "-")}.png`
              );
            } catch (e) {
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {!isLoading && "Save Card"}
          {isLoading && "Saving..."}
        </Button>
      </div>
    </div>
  );
};

export default App;
