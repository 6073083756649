import { FC } from "react";
import "../css/card.css";

const _serializeToString = window["XMLSerializer"].prototype.serializeToString;

window["XMLSerializer"].prototype.serializeToString = function (node: any) {
  return _serializeToString.call(this, node).replace(
    /background-image:/g,
    "-webkit-background-clip: text; background-image:" // Add the -webkit-background-clip
  );
};

const sanity = (text: string) => {
  text = text.replaceAll(
    /!([a-zA-Z0-9 ]*)!/gm,
    (_substring, args) => `<span class="bold">${args}</span>`
  );

  return text
    .split(/\r\n|\n|\r/gm)
    .map((line) => `${line}<br />`)
    .join("");
};

const Line = () => (
  <svg viewBox="0 0 825 4">
    <line
      x1="0"
      y1="1"
      x2="825"
      y2="1"
      stroke="currentColor"
      strokeDasharray="25 25"
    />
  </svg>
);

export const Card = ({
  title,
  tagline,
  description,
  win,
  alignment,
  alignmentIcon,
  textColor,
  color1,
  color2,
  titleColor1,
  titleColor2,
}: {
  title: string;
  tagline: string;
  description: string;
  win: string;
  alignment: string;
  alignmentIcon: FC;
  textColor: string;
  color1: string;
  color2: string;
  titleColor1: string;
  titleColor2: string;
}) => {
  return (
    <div
      id="card"
      style={
        {
          "--card-text-color": textColor,
          "--card-color-1": color1,
          "--card-color-2": color2,
          "--card-title-color-1": titleColor1,
          "--card-title-color-2": titleColor2,
        } as any
      }
    >
      <div id="corner1" />
      <div id="corner2" />

      <div id="alignment">
        {alignmentIcon({})}

        {alignment}
      </div>

      <div id="title-holder">
        <p id="title" dangerouslySetInnerHTML={{ __html: sanity(title) }}></p>

        <p
          id="tagline"
          className="center"
          dangerouslySetInnerHTML={{ __html: sanity(tagline) }}
        ></p>
      </div>

      <div id="gradient">
        <Line />

        <p className="subtitle">Description</p>

        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: sanity(description) }}
        ></p>

        <Line />

        <p className="subtitle">Win Condition</p>

        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: sanity(win) }}
        ></p>
      </div>
    </div>
  );
};
