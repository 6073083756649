import "../css/button.css";
import { PropsWithChildren, useMemo } from "react";

type Color = "blue";

export const Button = ({
  children,
  color = "blue",
  onClick,
}: PropsWithChildren<{ color?: Color; onClick?: () => any }>) => {
  const [color1, color2, textColor] = useMemo(() => {
    if (color === "blue") return ["#37afff", "#3190cf", "#FFFFFF"];

    return ["", "", ""];
  }, [color]);

  return (
    <button
      onClick={onClick}
      className="button"
      style={
        {
          "--button-color-1": color1,
          "--button-color-2": color2,
          "--button-text-color": textColor,
        } as any
      }
    >
      {children}
    </button>
  );
};
