import "../css/combo.css";
import { Dispatch, ReactNode, SetStateAction } from "react";

export const Combo = <T extends any>({
  name,
  options,
  render,
  state,
}: {
  name: string;
  options: { key: string; value: T }[];
  render: (option: T, key: string, index: number) => ReactNode;
  state: [T, SetStateAction<Dispatch<T>>];
}) => (
  <div>
    <p>{name}:</p>

    <div style={{ height: "0.5rem" }} />

    <select
      name={name}
      onChange={(e) => {
        const value = options.find(({ key }) => key === e.target.value)?.value;

        if (!value) return;

        state[1]((() => value) as any);
      }}
      value={options.find(({ value }) => value === state[0])?.key}
    >
      {options.map(({ key, value }, i) => (
        <option value={key} key={key}>
          {render(value, key, i)}
        </option>
      ))}
    </select>
  </div>
);
