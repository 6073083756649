import "../css/input.css";
import { Dispatch, ReactNode, SetStateAction } from "react";

export const Input = ({
  state,
  name,
  rows,
  left,
}: {
  state: [string, Dispatch<SetStateAction<string>>];
  name: string;
  rows?: number;
  left?: ReactNode;
}) => {
  return (
    <div>
      <p>{name}:</p>

      <div style={{ height: "0.5rem" }} />

      <div className="input-flex">
        {left || null}

        {rows ? (
          <textarea
            value={state[0]}
            rows={rows}
            onChange={(e) => {
              state[1](e.target.value);
            }}
          />
        ) : (
          <input
            type="text"
            value={state[0]}
            onChange={(e) => {
              state[1](e.target.value);
            }}
          />
        )}
      </div>
    </div>
  );
};
